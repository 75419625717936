'use client'

import { PropsWithChildren } from 'react'

import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeader,
  DialogOverlay,
} from '@wartek-id/dialog'

export function DesktopModal({
  children,
  title,
  show,
  onClose,
  size = 'md',
}: PropsWithChildren<{
  title: string
  show: boolean
  onClose: () => void
  size?: 'sm' | 'md' | 'lg'
}>) {
  return (
    <Dialog onClose={onClose} isOpen={show} size={size}>
      <DialogOverlay />
      <DialogContent>
        <DialogHeader title={title} variant="compact" />
        {children}
        <DialogCloseButton data-testid="btn-close-dialog" />
      </DialogContent>
    </Dialog>
  )
}
