import { ITEM_LAINNYA } from './constants'

export const getButtonDisableState = ({
  goal,
  sector,
  goalOther,
  sectorOther,
}: {
  goal: string
  goalOther: string
  sector: string
  sectorOther: string
}) => {
  if (goal === '' || sector === '') {
    return true
  } else if (goal === ITEM_LAINNYA.id || sector === ITEM_LAINNYA.id) {
    if (goal === ITEM_LAINNYA.id && goalOther === '') {
      return true
    } else if (sector === ITEM_LAINNYA.id && sectorOther === '') {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
