export const ITEM_LAINNYA = {
  id: 'LAINNYA',
  text: 'Lainnya',
}

export const OPTIONS_GOALS = [
  {
    id: 'PEMBUATAN KEBIJAKAN',
    text: 'Referensi Pembuatan Kebijakan',
  },
  {
    id: 'ACUAN EVALUASI',
    text: 'Acuan Monitoring atau Evaluasi Program',
  },
  {
    id: 'KAJIAN BISNIS',
    text: 'Mencari Referensi Data Terkait Pendidikan Untuk Kajian Bisnis/Jasa/Pekerjaan Profesional Lainnya',
  },
  {
    id: 'PENELITIAN',
    text: 'Referensi Penelitian/Tugas/Karya Ilmiah',
  },
  {
    id: 'PRIBADI',
    text: 'Referensi Pribadi',
  },
  ITEM_LAINNYA,
]

export const OPTIONS_SECTORS = [
  {
    id: 'SETJEN KEMENDIKBUDRISTEK',
    text: 'Sekretariat Jenderal Kemendikbudristek',
  },
  {
    id: 'DITJEN GTK',
    text: 'Direktorat Jenderal Guru dan Tenaga Kependidikan',
  },
  {
    id: 'DITJEN PAUD DIKDASMEN',
    text: 'Direktorat Jenderal PAUD, Pendidikan Dasar, dan Pendidikan Menengah',
  },
  {
    id: 'DITJEN VOKASI',
    text: 'Direktorat Jenderal Pendidikan Vokasi',
  },
  {
    id: 'DITJEN DIKTI RISTEK',
    text: 'Direktorat Jenderal Pendidikan Tinggi, Riset, dan Teknologi',
  },
  {
    id: 'BSKAP',
    text: 'Badan Standar, Kurikulum, dan Asesmen Pendidikan',
  },
  {
    id: 'BIRO HUKUM',
    text: 'Biro Hukum Kemendikbudristek',
  },
  {
    id: 'BIRO KERJASAMA DAN HUMAS',
    text: 'Biro Kerjasama dan Hubungan Masyarakat',
  },
  {
    id: 'BIRO UMUM DAN PENGADAAN',
    text: 'Biro Umum dan Pengadaan Barang dan Jasa',
  },
  {
    id: 'BLPT DAN PUSDATIN',
    text: 'Balai Layanan Platform Teknologi (BLPT) dan Pusat Data dan Informasi (Pusdatin)',
  },
  {
    id: 'UNIT KEMENDIKBUD LAIN',
    text: 'Unit Utama/Unit Kerja Kemendikbudristekdikti Lainnya',
  },
  {
    id: 'KEMENTRIAN LAIN',
    text: 'Kementrian dan Lembaga di luar Kemendikbudristekdikti',
  },
  {
    id: 'LEMBAGA RISET',
    text: 'Lembaga Riset/Peneliti/Pengamat',
  },
  {
    id: 'DISDIK',
    text: 'Dinas Pendidikan',
  },
  {
    id: 'AKADEMISI',
    text: 'Akademisi',
  },
  {
    id: 'MASYARAKAT',
    text: 'Masyarakat Umum',
  },
  ITEM_LAINNYA,
]

export const OPTIONS_RATINGS = [
  {
    id: 1,
    text: 'Sangat Buruk',
  },
  {
    id: 2,
    text: 'Buruk',
  },
  {
    id: 3,
    text: 'Cukup',
  },
  {
    id: 4,
    text: 'Baik',
  },
  {
    id: 5,
    text: 'Sangat Baik',
  },
]

export type SurveySource = 'PUBLIKASI' | 'DATASET' | 'DATA_INDUK' | 'PRIORITAS'
