'use client'

import { PropsWithChildren } from 'react'

import { Button } from '@wartek-id/button'
import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'

import { ASSET_PREFIX } from '@/configs/constants'

import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal'

export function SuccessSurvey({
  show,
  onClose,
}: PropsWithChildren<{
  show: boolean
  onClose: () => void
}>) {
  return (
    <ResponsiveModal
      show={show}
      onClose={onClose}
      title="Survey Kepuasan"
      size="md"
    >
      <img
        src={`${ASSET_PREFIX}/images/illustration-survey-popup.svg`}
        alt="A women filling a survey form"
        className="h-auto w-full"
        loading="lazy"
      />
      <section className="space-y-4 p-4">
        <Text variant="heading-md">
          Terimakasih telah membantu kami dalam rangka meningkatkan layanan
          Portal Data Kemendikbudristek
        </Text>
        <Separator />
        <div className="flex justify-between gap-4">
          <Button
            fullWidth
            data-testid={`btn-close-success-survey`}
            onClick={onClose}
          >
            Tutup
          </Button>
        </div>
      </section>
    </ResponsiveModal>
  )
}
