'use client'

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react'

import {
  trackClickOpenSurvey_DataInduk,
  trackClickOpenSurvey_Dataset,
  trackClickOpenSurvey_PlatformPrioritas,
  trackClickOpenSurvey_Publikasi,
} from '@/utils/tracker/modules/common'

import { SurveySource } from './constants'
import { DownloadSurvey } from './DownloadSurvey'

export const DownloadSurveyContext = createContext({
  showSurvey: (_source?: SurveySource) => {},
})

export const DownloadSurveyProvider = ({
  children,
  initialSource,
}: PropsWithChildren<{ initialSource?: SurveySource }>) => {
  const [isShowSurveyDialog, setShowSurveyDialog] = useState<boolean>(false)
  const [surveySource, setSurveySource] = useState<SurveySource>(
    initialSource || 'DATASET'
  )

  const showSurvey = useCallback(
    (source: SurveySource) => {
      if (source) {
        setSurveySource(source)
      }
      setShowSurveyDialog(true)

      const trackerSource = source || surveySource
      if (trackerSource === 'PUBLIKASI') {
        trackClickOpenSurvey_Publikasi()
      } else if (trackerSource === 'DATASET') {
        trackClickOpenSurvey_Dataset()
      } else if (trackerSource === 'DATA_INDUK') {
        trackClickOpenSurvey_DataInduk()
      } else if (trackerSource === 'PRIORITAS') {
        trackClickOpenSurvey_PlatformPrioritas()
      }
    },
    [surveySource]
  )

  return (
    <DownloadSurveyContext.Provider
      value={{
        showSurvey,
      }}
    >
      {children}
      <DownloadSurvey
        show={isShowSurveyDialog}
        onClose={() => {
          setShowSurveyDialog(false)
        }}
        source={surveySource}
      />
    </DownloadSurveyContext.Provider>
  )
}

export const useDownloadSurvey = () => {
  const { showSurvey } = useContext(DownloadSurveyContext)

  return {
    showSurvey,
  }
}
