'use client'

import { PropsWithChildren } from 'react'

import { useMedia } from '@wartek-id/react-utils'

import { DesktopModal } from './DesktopModal'
import { MobileModal } from './MobileModal'

export function ResponsiveModal({
  children,
  title,
  show,
  onClose,
  size = 'md',
}: PropsWithChildren<{
  title: string
  show: boolean
  onClose: () => void
  size?: 'sm' | 'md' | 'lg'
}>) {
  const isMd = useMedia('(min-width: 768px)', false)

  return (
    <>
      {isMd && (
        <DesktopModal title={title} show={show} onClose={onClose} size={size}>
          {children}
        </DesktopModal>
      )}
      {!isMd && (
        <MobileModal title={title} show={show} onClose={onClose}>
          {children}
        </MobileModal>
      )}
    </>
  )
}
