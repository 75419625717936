'use client'

import styles from './survey-styles.module.css'

import clsx from 'clsx'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'
import { Input } from '@wartek-id/input'
import { useMedia } from '@wartek-id/react-utils'
import { Select } from '@wartek-id/select'
import { SelectionBox, SelectionBoxOption } from '@wartek-id/selection-box'
import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'
import { Textarea } from '@wartek-id/textarea'

import Storage from '@/utils/storage'
import {
  trackClickSurveyCloseButton_DataInduk,
  trackClickSurveyCloseButton_Dataset,
  trackClickSurveyCloseButton_PlatformPrioritas,
  trackClickSurveyCloseButton_Publikasi,
  trackClickSurveySubmitButton_DataInduk,
  trackClickSurveySubmitButton_Dataset,
  trackClickSurveySubmitButton_PlatformPrioritas,
  trackClickSurveySubmitButton_Publikasi,
} from '@/utils/tracker/modules/common'

import { ASSET_PREFIX } from '@/configs/constants'

import {
  ITEM_LAINNYA,
  OPTIONS_GOALS,
  OPTIONS_RATINGS,
  OPTIONS_SECTORS,
  SurveySource,
} from './constants'
import { getButtonDisableState } from './helpers'
import { SuccessSurvey } from './SuccessSurvey'
import { EmptyState } from '../EmptyState'
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal'

type FormSurveyState = {
  rating: number
  goal: string
  goalOther: string
  sector: string
  sectorOther: string
  feedback: string
}

const FEEDBACK_MAX_LENGTH = 1000
const OTHER_MAX_LENGTH = 200
const DEFAULT_STATE: FormSurveyState = {
  rating: 3,
  goal: '',
  goalOther: '',
  sector: '',
  sectorOther: '',
  feedback: '',
}

const STORAGE_FORM_SURVEY = '__dpsrvy'

function FallbackError() {
  return (
    <EmptyState
      title="Oops, gagal memuat form survey"
      description="Terjadi kesalahan pada sistem saat memuat form survey! Silahkan coba lagi nanti."
      size="sm"
    />
  )
}

export function DownloadSurvey({
  show,
  onClose,
  source,
}: PropsWithChildren<{
  show: boolean
  onClose: () => void
  source: SurveySource
}>) {
  const [isDisableSubmit, setIsDisableSubmit] = useState(true)
  const [isShowSuccessSurvey, setIsShowSuccessSurvey] = useState(false)
  const storage = useMemo(
    () => new Storage<FormSurveyState>(STORAGE_FORM_SURVEY, DEFAULT_STATE),
    []
  )

  const isSm = useMedia('(min-width: 640px)', false)

  const [formState, setFormState] = useState<FormSurveyState>(DEFAULT_STATE)

  const handleChangeForm = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }))
  }

  const handleClose = (withTracker: boolean) => {
    if (withTracker) {
      if (source === 'PUBLIKASI') {
        trackClickSurveyCloseButton_Publikasi()
      } else if (source === 'DATASET') {
        trackClickSurveyCloseButton_Dataset()
      } else if (source === 'DATA_INDUK') {
        trackClickSurveyCloseButton_DataInduk()
      } else if (source === 'PRIORITAS') {
        trackClickSurveyCloseButton_PlatformPrioritas()
      }
    }

    onClose()
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isDisableSubmit) {
      // Sent to event tracker
      const trackerParams = {
        nilai: `${formState.rating}`,
        sektor: formState.sector,
        sektor_lain: formState.sectorOther,
        tujuan: formState.goal,
        tujuan_lain: formState.goalOther,
        saran: (formState?.feedback || '').substring(0, FEEDBACK_MAX_LENGTH),
      }

      if (source === 'PUBLIKASI') {
        trackClickSurveySubmitButton_Publikasi(trackerParams)
      } else if (source === 'DATASET') {
        trackClickSurveySubmitButton_Dataset(trackerParams)
      } else if (source === 'DATA_INDUK') {
        trackClickSurveySubmitButton_DataInduk(trackerParams)
      } else if (source === 'PRIORITAS') {
        trackClickSurveySubmitButton_PlatformPrioritas(trackerParams)
      }

      storage.setValue({
        ...formState,
        feedback: '', // Doesn't need to store feedback
      })

      handleClose(false)
      setIsShowSuccessSurvey(true)
    }
  }

  const handleCloseSuccessSurvey = () => {
    setIsShowSuccessSurvey(false)
  }

  useEffect(() => {
    if (show) {
      const storageVal = storage.getValue()
      if (storageVal) {
        setFormState(storageVal)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  useEffect(() => {
    setIsDisableSubmit(getButtonDisableState(formState))
  }, [formState])

  return (
    <>
      {process.env.NEXT_PUBLIC_FEATURE_SURVEY_POPUP === 'y' && (
        <>
          <ResponsiveModal
            show={show}
            onClose={() => {
              handleClose(true)
            }}
            title="Survey Kepuasan"
            size="md"
          >
            <ErrorBoundary FallbackComponent={FallbackError}>
              <img
                src={`${ASSET_PREFIX}/images/illustration-survey-popup.svg`}
                alt="A women filling a survey form"
                className="h-auto w-full"
                loading="lazy"
              />

              <form className="space-y-4 p-4" onSubmit={onSubmit}>
                <Text variant="heading-md">
                  Bantu kami tingkatkan layanan dan pengembangan Portal Data
                  Kemendikbudristek
                </Text>
                <Separator />
                <div className="flex flex-col gap-2">
                  <Text variant="body-bold">
                    Dalam skala 1-5 seberapa baik layanan di Portal Data
                    Kemendikbudristek?
                  </Text>
                  <div className="w-full rounded-md bg-gray-10 px-4 py-2">
                    <SelectionBox
                      value={`${formState.rating || ''}`}
                      onChange={(newValue) => {
                        handleChangeForm('rating', parseInt(newValue, 10))
                      }}
                      className="mt-2"
                    >
                      <div className="grid grid-cols-5 items-start gap-3">
                        {OPTIONS_RATINGS.map((option) => (
                          <SelectionBoxOption
                            key={option.id}
                            value={`${option.id}`}
                            data-testid={`rating-${option.id}`}
                            className={({ active, checked }) => {
                              return clsx(
                                styles['survey-rating'],
                                active ? 'is--active' : '',
                                checked ? 'is--checked' : ''
                              )
                            }}
                            aria-label={option.text}
                          >
                            <div className="rating-box">
                              <Text
                                variant={isSm ? 'display-lg' : 'body-lg-bold'}
                                color="inherit"
                              >
                                {option.id}
                              </Text>
                            </div>
                            <Text
                              variant={isSm ? 'body' : 'body-sm'}
                              color="inherit"
                            >
                              {option.text}
                            </Text>
                          </SelectionBoxOption>
                        ))}
                      </div>
                    </SelectionBox>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <Text variant="body-bold">Tujuan Anda menggunakan data</Text>
                  <Select
                    dropdownIcon={<Icon>expand_more</Icon>}
                    data-testid="combo-goal"
                    className="!py-2"
                    value={formState.goal}
                    onChange={(e) => {
                      handleChangeForm('goal', e.target.value)
                      handleChangeForm('goalOther', '')
                    }}
                  >
                    <option value="">Pilih tujuan</option>
                    {OPTIONS_GOALS.map((goal) => (
                      <option key={goal.id} value={goal.id}>
                        {goal.text}
                      </option>
                    ))}
                  </Select>
                  {formState.goal === ITEM_LAINNYA.id && (
                    <Input
                      placeholder="Tulis tujuan lainnya"
                      data-testid="txt-goal-other"
                      value={formState.goalOther}
                      onChange={(e) => {
                        handleChangeForm('goalOther', e.target.value)
                      }}
                      maxLength={OTHER_MAX_LENGTH}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <Text variant="body-bold">
                    Pilih sektor/grup yang berikut yang menggambarkan Anda
                  </Text>
                  <Select
                    dropdownIcon={<Icon>expand_more</Icon>}
                    data-testid="combo-sector"
                    className="!py-2"
                    value={formState.sector}
                    onChange={(e) => {
                      handleChangeForm('sector', e.target.value)
                      handleChangeForm('sectorOther', '')
                    }}
                  >
                    <option value="">Pilih sektor</option>
                    {OPTIONS_SECTORS.map((sector) => (
                      <option key={sector.id} value={sector.id}>
                        {sector.text}
                      </option>
                    ))}
                  </Select>
                  {formState.sector === ITEM_LAINNYA.id && (
                    <Input
                      placeholder="Tulis sektor/group lainnya"
                      data-testid="txt-sector-other"
                      value={formState.sectorOther}
                      onChange={(e) => {
                        handleChangeForm('sectorOther', e.target.value)
                      }}
                      maxLength={OTHER_MAX_LENGTH}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <Text variant="body-bold">
                    Berikan saran dan masukan Anda terkait layanan Portal Satu
                    Data (Optional)
                  </Text>
                  <Textarea
                    placeholder="Tuliskan saran dan masukan disini"
                    value={formState.feedback}
                    onChange={(e) => {
                      handleChangeForm('feedback', e.target.value)
                    }}
                    data-testid={`txt-feedback`}
                    maxLength={FEEDBACK_MAX_LENGTH}
                  ></Textarea>
                </div>
                <div className="flex justify-between gap-4">
                  <Button
                    fullWidth
                    color="white"
                    onClick={() => {
                      handleClose(true)
                    }}
                    data-testid={`btn-dismiss-survey`}
                  >
                    Nanti
                  </Button>
                  <Button
                    fullWidth
                    type="submit"
                    data-testid={`btn-submit-survey`}
                    disabled={isDisableSubmit}
                  >
                    Kirim
                  </Button>
                </div>
              </form>
            </ErrorBoundary>
          </ResponsiveModal>
          <SuccessSurvey
            show={isShowSuccessSurvey}
            onClose={handleCloseSuccessSurvey}
          />
        </>
      )}
    </>
  )
}
