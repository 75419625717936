'use client'

import { PropsWithChildren } from 'react'

import {
  Sheet,
  SheetBody,
  SheetCloseButton,
  SheetContent,
  SheetOverlay,
  SheetTitle,
} from '@wartek-id/sheet'

export function MobileModal({
  children,
  title,
  show,
  onClose,
}: PropsWithChildren<{
  title: string
  show: boolean
  onClose: () => void
}>) {
  return (
    <Sheet onClose={onClose} isOpen={show} sheetSize="xl">
      <SheetOverlay />
      <SheetContent>
        <SheetTitle
          bordered
          bold
          as="header"
          style={{
            height: 'auto',
            minHeight: '54px',
            paddingRight: '50px',
            flex: '1 1 auto',
          }}
        >
          {title}
        </SheetTitle>
        <SheetBody>{children}</SheetBody>
        <SheetCloseButton data-testid="btn-close-sheet" />
      </SheetContent>
    </Sheet>
  )
}
